import React from "react";
import { Layout } from "../../../components/Layout";
import { Seo } from "../../../components/Seo";
import {
  ServicesBenefitsWrapper,
  ServicesBenefit,
  ServicesBenefitsList,
} from "../../../components/BenefitList";
import { SectionTitle } from "../../../components/Section";
import { PageHeroWrap } from "../../../components/PageHero";
import { MdxTestimonialsSection } from "../../../components/Testimonials/MdxTestimonials";
import { MdxCaseStudiesSection } from "../../../components/CaseStudies/MdxCaseStudies";
import { MdxBlogPostsSection } from "../../../components/Blog/MdxBlogPosts";
import { Image } from "../../../components/Image";
import teamIcon from "../../../images/benefits/team-icon.svg";
import controlIcon from "../../../images/benefits/control-icon.svg";
import scalabilityIcon from "../../../images/benefits/scalability-icon.svg";
import zeroAdministrationIcon from "../../../images/benefits/zeroAdministration-icon.svg";
import professionalGrowthIcon from "../../../images/benefits/professionalGrowth-icon.svg";
import costEffectivenessIcon from "../../../images/benefits/costEffectiveness-icon.svg";
import requirementsImg from "../../../images/howItWorks/requirements.svg";
import recruitingImg from "../../../images/howItWorks/recruiting.svg";
import developmentImg from "../../../images/howItWorks/development.svg";
import setupImg from "../../../images/howItWorks/launch.svg";
import interviewImg from "../../../images/howItWorks/interview.svg";

import heroImg from "../../../images/undraw/team-extension.svg";
import ogImage from "./og_team.png";
import { graphql, useStaticQuery } from "gatsby";
import {
  HeaderContainer,
  Text,
  Title,
} from "../../../components/Services/HeaderContainer";
import { Button } from "../../../components/Button";
import { getContactUrl } from "../../../utils/urls";
import * as styles from "./index.module.scss";
import {
  HowItWorksItem,
  HowItWorksWrapper,
} from "../../../components/HowItWorksItem";

const RemoteHero = () => {
  return (
    <PageHeroWrap className={styles.hero_outer}>
      <HeaderContainer
        title={<Title>Team Extension</Title>}
        image={
          <Image
            image={heroImg}
            alt="Team Extension"
            className={styles.header_image}
          />
        }
        text={
          <Text>
            We recognize that the IT landscape is ever-evolving and your needs
            can change overnight. With our Team Extension service, you get more
            than just additional hands on deck; you gain immediate access to
            specialized talent and cutting-edge expertise.
          </Text>
        }
        btn={<Button to={getContactUrl()}>Contact Us</Button>}
      />
    </PageHeroWrap>
  );
};

const Page = () => {
  const data = useStaticQuery(graphql`
    query {
      blogPosts: allMdx(
        filter: {
          fields: { collection: { eq: "blog" } }
          frontmatter: { priority: { gt: 0 }, tags: { eq: "team" } }
        }
        sort: { fields: frontmatter___priority }
        limit: 5
      ) {
        ...BlogPostsData
      }

      testimonials: allMdx(
        filter: {
          fields: { collection: { eq: "testimonials" } }
          frontmatter: { priority: { gt: 0 }, tags: { eq: "team" } }
        }
        sort: { fields: frontmatter___priority }
        limit: 5
      ) {
        ...TestimonialsData
      }

      cases: allMdx(
        filter: {
          fields: { collection: { eq: "cases" } }
          frontmatter: { priority: { gt: 0 }, tags: { eq: "team" } }
        }
        sort: { fields: frontmatter___priority }
      ) {
        ...CaseStudiesData
      }
    }
  `);

  return (
    <Layout>
      <Seo
        ogFullTitle
        title="Team Extension Service"
        description="Effortlessly build your remote R&D department on time by gaining direct access to one of the largest European IT talent pools."
        ogImage={ogImage}
        maxImagePreview="large"
      />
      <RemoteHero />
      <ServicesBenefitsWrapper>
        <SectionTitle>Benefits</SectionTitle>
        <ServicesBenefitsList>
          <ServicesBenefit
            image={<Image image={teamIcon} alt="Relevant Professionals" />}
            title="Relevant Professionals"
          >
            With our proven recruiting processes and more than 200K developers
            in Ukraine, we can assemble the team according to your requirements,
            technical skills, industry, and specific expertise you need.
          </ServicesBenefit>
          <ServicesBenefit
            image={<Image image={controlIcon} alt="Full Control" />}
            title="Full Control"
          >
            Treat your remote team extension almost as if they were your
            employees. You'll conduct second-stage interviews and make the final
            hiring decision. Your team members will follow your processes,
            company culture, and corporate values.
          </ServicesBenefit>
          <ServicesBenefit
            image={<Image image={scalabilityIcon} alt="Scalability" />}
            title="Scalability"
          >
            Remote Teams can be scaled up and down depending on the planned work
            scope. You can smoothly bring in more people should you need to
            expand a project, and you won't have to fire employees if you no
            longer need their services.
          </ServicesBenefit>
          <ServicesBenefit
            image={
              <Image image={zeroAdministrationIcon} alt="Zero Administration" />
            }
            title="Zero Administration"
          >
            Instead of being bogged down by recruitment processes, payroll
            complexities, and talent management, you can redirect those
            resources and time back to your core business objectives. You get
            the expertise you need without the red tape you don't.
          </ServicesBenefit>
          <ServicesBenefit
            image={
              <Image image={professionalGrowthIcon} alt="Professional Growth" />
            }
            title="Professional Growth"
          >
            Every LeanyLabs engineer receives an individual professional
            development plan aligned with personal goals, regular performance
            reviews, and additional training opportunities. We actively support
            mentoring and knowledge sharing inside the company.
          </ServicesBenefit>
          <ServicesBenefit
            image={
              <Image image={costEffectivenessIcon} alt="Cost-Effectiveness" />
            }
            title="Cost-Effectiveness"
          >
            Team Extension cost is more predictable compared to in-house
            development. You won't have to pay for the office space, equipment,
            training, and employee benefits on top of their compensation.
          </ServicesBenefit>
        </ServicesBenefitsList>
      </ServicesBenefitsWrapper>

      <HowItWorksWrapper>
        <SectionTitle>How it Works</SectionTitle>
        <HowItWorksItem
          title="Gathering Requirements"
          image={<Image image={requirementsImg} alt="Gathering Requirements" />}
        >
          We hold a series of meetings to clarify the project's details, desired
          team composition, requirements for candidates, and different
          organizational aspects.
        </HowItWorksItem>
        <HowItWorksItem
          title="Recruiting"
          image={<Image image={recruitingImg} alt="Recruiting" />}
        >
          We actively start searching for candidates, screening them, and
          conducting extensive soft&hard skills interviews. You'll receive
          detailed feedback on every great candidate and decide if they should
          pass to the next stage.
        </HowItWorksItem>
        <HowItWorksItem
          title="Client Interview"
          image={<Image image={interviewImg} alt="Client Interview" />}
        >
          We will arrange an interview with you where you'll drive the process.
          Our technical expert might be there to double-check the candidate.
          Afterward, we share our opinions and decide whether they fit the team
          well.
        </HowItWorksItem>
        <HowItWorksItem
          title="Setup"
          image={<Image image={setupImg} alt="Setup" />}
        >
          Switching to a new job usually takes around two weeks. Meanwhile,
          we'll prepare everything they need for productive work. LeanyLabs'
          onboarding process usually takes only a day.
        </HowItWorksItem>
        <HowItWorksItem
          title="Development"
          image={<Image image={developmentImg} alt="Development" />}
        >
          A new team member will join your engineering team. We recommend
          onboarding them as you would do for your on-site employees. They must
          get to know each other, review the project, read the documentation,
          and get familiar with the processes before the development process
          gains full speed.
        </HowItWorksItem>
      </HowItWorksWrapper>

      <MdxTestimonialsSection testimonialsData={data.testimonials} />
      <MdxCaseStudiesSection caseStudiesData={data.cases} />
      <MdxBlogPostsSection blogPostsData={data.blogPosts} />
    </Layout>
  );
};

export default Page;
